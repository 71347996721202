import React from 'react';
import FreedomWrapper from '../SharedComponent/FreedomWrapper/FreedomWrapper';
import TopNavBar1 from '../TopNavBar1';
import TraderPuginContent from '../SharedComponent/TraderPlugin/TraderPuginContent';
import { styled } from '@mui/styles';

const Wrapper = styled('div')(({theme}) => ({
    height: 'calc(100vh - 250px)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }))

const TraderPluginPage = () => {
  return (
    <>
    <TopNavBar1 />
    <Wrapper>
      <TraderPuginContent />
    </Wrapper>
    </>
  );
};

export default TraderPluginPage;
