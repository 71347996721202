/**
 * Main Dashboard youtube video links
 */

export const mainDashboardVideoLinks = () => {
    return "https://youtu.be/g83w6LHodJA"
    // return "https://www.youtube.com/watch?v=FiX85JNYmow"
}
export const investmentDashboardVideoLinks = () => {
    return "https://youtu.be/1CJL4NUr_CA"
    // return "https://www.youtube.com/watch?v=FiX85JNYmow"
}
export const affiliateDashboardVideoLinks = () => {
    return "https://youtu.be/JFAo6-hF5Do"
}
export const voucherVideoLinks = () => {
    return "https://youtu.be/68lwDwHssXY"
}
export const voucherDashboardVideoLinks = () => {
    return "https://youtu.be/68lwDwHssXY"
}
export const productSettingsVideoLinks = () => {
    return "https://youtu.be/CpFQL3Hf8Zc"
}
export const profileSettingsVideoLinks = () => {
    return "https://youtu.be/a7zqZuqDxKE"
}
export const connectBrokerVideoLinks = () => {
    return "https://youtu.be/49W9myEyKwo"
}

/**
 * Add new Affiliate video links
 */

export const invitationUser = (userType, activeStep) => {
    if (activeStep >= 0) {
        if (userType === 2) {
          switch (activeStep) {
            case 0:
              return 'https://www.youtube.com/watch?v=ORPH0FOLUPc';
            case 1:
              return 'https://www.youtube.com/watch?v=ORPH0FOLUPc';
            case 2:
              return 'https://www.youtube.com/watch?v=ORPH0FOLUPc';
            case 3:
              return 'https://www.youtube.com/watch?v=ORPH0FOLUPc';
            default:
              return 'https://www.youtube.com/watch?v=ORPH0FOLUPc';
          }
        } else if (userType === 1) {
          switch (activeStep) {
            case 0:
              return 'https://youtu.be/OfAM5y-YwBA';
            case 1:
              return 'https://youtu.be/OfAM5y-YwBA';
            case 2:
              return 'https://youtu.be/OfAM5y-YwBA';
            case 3:
              return 'https://youtu.be/OfAM5y-YwBA';
            default:
              return 'https://youtu.be/OfAM5y-YwBA';
          }
        } else if (userType === 3) {
          switch (activeStep) {
            case 0:
              return 'https://youtu.be/THAgUabGi_w';
            case 1:
              return 'https://youtu.be/THAgUabGi_w';
            case 2:
              return 'https://youtu.be/THAgUabGi_w';
              case 3:
                return 'https://youtu.be/THAgUabGi_w';
            default:
              return 'https://youtu.be/THAgUabGi_w';
          }
        }
      } else {
        if (userType === 2) {
          return 'https://www.youtube.com/embed/yafLCWcu7q8';
        } else if (userType === 1) {
          return 'https://www.youtube.com/embed/eP3IyJAMb8U';
        } else if (userType === 3) {
          return 'https://youtu.be/THAgUabGi_w';
        }
      }
}

/**
 * plan selection url
 */

export const selectPlanVideoLinks = () => {
  return "https://youtu.be/yTr0ywOzMu0"
}
export const updatePlanVideoLinks = () => {
  return "https://www.youtube.com/watch?v=ZpG3G6V79-4"
}