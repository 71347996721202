import { getBaseUrl, getBasicUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();
const siteUrl = getBasicUrl();

export const getPlatformUrl = (os) => {
  return `${baseUrl}platforms?$filter=Name eq '${os}'&$select=Id&$top=1`;
};

// export const getUserIdentificationNumberURl = ()=>{
//     return `${baseUrl}users?$filter=UserId eq @UserId&$top=1&select=PersonalIdentificationNumberMasked,Nationality,DateOfBirth&$orderby=CreatedOn desc`
// }
export const getUserIdentificationNumberURl = () => {
  return `${baseUrl}UserPersonalIdentifications?$filter=UserId eq @UserId&$top=1&select=PersonalIdentificationNumberMasked,Nationality,DateOfBirth&$orderby=CreatedOn desc`;
};

export const getUserAndAddressUrl = () => {
  return `${baseUrl}UserAddresses?$filter=UserId eq @UserId&$select=Id,residentialAddress,permanentAddress,FullAddress&$orderby=CreatedOn desc&$expand=User($select=Id,Givenname,TaxableCountryId,Surname,Nationality,AlternateEmail,PhoneNumber,CountryOfBirthId,TaxableCountryId)`;
};

export const getUsersUrl = () => {
  return `${baseUrl}users?$filter=Id eq @UserId&$top=1&$select=RowNumber,UserType,AccountTypeId,ParentUserId,ParentUserLeg,Active,OnboardingCompleted,Givenname,Surname,Username,AutoDeleteOn,ThemeOther,PrimaryPaymentMethodId,PrimaryPaymentMethodType,ProfileType,level,Id,InvitedByUserId,TermsAndConditionsAgreed,PrivacyPolicyAgreed,RiskAndWarningAgreed,InvitationPlatformType,AffiliateAgreementAgreed, AboutMe, HasProfilePicture,AffiliateLevelId,AlternateEmail, memberNumber`;
  // return `${baseUrl}users?$filter=AadObjectId eq @AadObjectId&$top=1&$select=Id,UserType,ParentUserId,ParentUserLeg,Active,OnboardingCompleted,Givenname,Surname,Username,AutoDeleteOn,ThemeOther`
  // return `${baseUrl}users?$filter=AadObjectId eq ${uniqueId}&$top=1&$select=Id,UserType,ParentUserId,ParentUserLeg,Active,OnboardingCompleted,Givenname,Surname,Username,AutoDeleteOn,ThemeOther`
};

// export const getUserPersonalIdentificationsUrl = (id) => {
//     return `${baseUrl}UserPersonalIdentifications?$filter=UserId eq @UserId&$top=1&$select=Givenname,Surname&$orderby=CreatedOn desc`
// }

export const getUserAddressesUrl = () => {
  return `${baseUrl}UserAddresses?$filter=UserId eq @UserId&$select=Id,PostalCode,City,StreetNumber,FullAddress, CountryId,State,CareOf,IsPrimaryAddress&$orderby=CreatedOn desc&$expand=User($select=AlternateEmail,PhoneNumber)`;
};

/**
 * get user balance
 */

export const getUserBalance = (accountTypeId) => {
  return `${baseUrl}userledgeraccounts?$filter=ledgerAccountTypeId eq ${accountTypeId}`;
};

export const getUserInfo = () => {
  return `${baseUrl}users?&select=Id,Givenname,Surname,username,phoneNumber,nationality,countryId,countryOfBirthId,AlternateEmail`;
};

// export const getUserAddressUrl = () => {
//     return `${baseUrl}UserAddresses`
// }

//UserCompanyInformations Api Url
export const getUserCompanyInformationUrl = () => {
  return `${baseUrl}usercompanyinformations`;
  // ?$filter=Id eq @UserId&$top=1&$select=RowNumber`;
};

//User specific Document Api Url
export const getUserSpecificDocument = (documentTypeId) => {
  return `${baseUrl}userdocuments?filter=documentTypeId eq ${documentTypeId}&$orderby=CreatedOn desc&$top=1`;
};

//Download Document Api Url
export const getDownloadDocument = (documentName) => {
  // devConsoleLog("getDownloadDocument",`${siteUrl}User/downloadfile('${documentName}')`);
  return `${siteUrl}User/downloadfile('${documentName}')`;
};

//Yearly Subscription Fee Api Url
// export const getYearlySucscription = () => {
//     // devConsoleLog("getDownloadDocument",`${siteUrl}User/downloadfile('${documentName}')`);
//     return `${siteUrl}data/subscriptions`
// }

//Check Terms And Condotions Api Url
export const getTermsAndConditionsApiUrl = () => {
  return `${siteUrl}data/(updatefreedomdocumentsstatus)`;
};

/**
 *  Transaction Fee End point
 */

export const getTransactionFee = () => {
  return `${baseUrl}gettransactionfee`;
};

export const getInvitationTemplates = (userType, conversionType=2) => {
  return `${baseUrl}getinvitationtemplates(${userType})?conversionType=${conversionType}`;
};

//Get User Address IncomeInfo And PersonalInfo
export const getUserAddressInfoIncomeInfoAndPersonalInfoByUserIDUrl = (UesrID) => {
  return `${baseUrl}GetUserAddressInfoIncomeInfoAndPersonalInfoByUserID('${UesrID}')`;
};
