// export const investorsdashboard = "/investorsdashboard"
// export const invitenewinvestor ="/invitenewinvestor"

export const DashboardUrls = {
  mainDashboard: '/maindashboard',
  getStartedWizard: '/getstarted',
  getStartedInvestment: '/getstarted/get-started-investment'
};

export const InvestorUrls = {
  investorMainScreen: '/investorsmainscreen',
  investorDashboard: '/investorsdashboard',
  inviteNewInvestor: '/invitenewinvestor',
  allInvestorDashboard: 'allinvestordashboard',
  pendingInvestors: '/allinvestordashboard/pendinginvestors',
  manageInvestors: '/allinvestordashboard/manageinvestors',
  inviteInvestor: '/inviteinvestor',
  allInvestors: '/allinvestordashboard/manageinvestors',
  allPendingInvestors: '/allinvestordashboard/pendinginvestors'
};

export const AffiliateUrls = {
  welcomeAffiliate: '/welcomeaffliate',
  affiliateDashboard: '/affliatesdashboard',
  inviteAffiliate: '/inviteaffiliate',
  inviteInvestorAsAffiliate: '/inviteinvestorasaffiliate',
  allAffiliateDashboard: 'allaffiliatedashboard',
  allAffiliateDownline: '/allaffiliatedashboard/downline',
  allAffiliateFirstline: '/allaffiliatedashboard/firstlineaffiliates',
  allAffiliate: '/allaffiliatedashboard/manageaffiliates',
  allPendingAffiliates: '/allaffiliatedashboard/pendingaffiliates',
  pendingAffiliate: 'pendingaffiliates',
  manageAffiliate: 'manageaffiliates',
  downline: 'downline',
  firstLineAffiliates: 'firstlineaffiliates'
};

export const InvestmentUrls = {
  investmentDashboard: '/investmentsdashboard',
  investmentDashboardMainScreen: '/investmentsdashboardmainscreen',
  selectInvestmentPlan: '/selectinvestmentplan',
  investmentPlanList: '/investmentplanlist',
  investmentOverallSummary: '/investmentoverallsummary',
  newInvestment: '/newinvestment',
  newInvestmentPlan: '/newinvestmentplan',
  basicNewInvestmentPackage: '/newinvestment/basicnewinvestmentpackage',
  autoInvest: '/autoinvest',
  autoRenew: '/autorenew',
  selectProduct: '/selectproduct',
  liveTrade: '/investmentlivetrade',
  // allLiveTrade: 'investmentlivetrade',
  openLiveTrade: '/investmentlivetrade/opentrade',
  historyLiveTrade: '/investmentlivetrade/historytrade',
  tradeName: '/investmenttradename',
  tradeHistoryName: '/tradehistoryname',
  // allTradeHistory: "alltradehistory",
  allTradeDashboard: '/investmenttradehistory',
  allTrade: '/alltrade',
  sellTrade: '/selltrade',
  buyTrade: '/buytrade'
};

/**
 * Traders urls
 */

export const TradersUrls = {
  freedomPlugin: '/freedom-plugin',
  tradersAccount: '/traders-account'
}

export const NewInvestmentUrls = {
  investmentLive: '/investmentLive',
  investmentDashboard: '/investmentDashboard',
  investmentProducts: '/investmentProducts',
  investmentSilverProducts: '/investmentSilverProducts',
  investmentGoldProducts: '/investmentGoldProducts',
  investmentGoldProductsLive: '/investmentGoldProductsLive',
  investmentPlatinumProducts: '/investmentPlatinumProducts',
  investmentPlatinumProductsLive: '/investmentPlatinumProductsLive',
  investmentMain: '/investment'
};

export const WalletUrls = {
  walletCardManagmentMainScreen: '/walletcardmanagmentmainscreen',
  walletWelcome: '/wallet',
  walletDashboard: '/walletdashboard',
  // wallet: "/wallet",
  ManagePayment: '/managepayment',
  makePrimaryBox: '/makeprimarybox',
  cashwithdrawSummary: '/cashwithdrawsummary',
  cashwithdraw: '/cashwithdraw',
  makePrimarysuccessDialog: '/makeprimarysuccessdialog',
  removepaymentdialog: '/removepaymentdialog',
  accountOverview: '/accountoverview',
  cashDeposit: '/cashdeposit',
  cashDepositChild: '/cashdepositchild',
  bankAccount: '/bankaccount',
  creditCard: '/creditcard',
  cryptoCurrency: '/cryptocurrency',
  withdrawRequest: '/withdrawrequest',
  addPrimaryMethod: '/addprimarymethod',
  internalTransfer: '/internaltransfer',
  overviewInternalTransfer: '/overviewinternaltransfer',
  overviewInternalTransferProcessInternalTransfer: '/overviewinternaltransfer/processinternaltransfer',
  transactionsHistory: '/walletdashboard/transactionshistory',
  WalletPaymentMethodRemove: '/walletpaymentmethodremove',
  cashWithdraw: '/cashwithdraw',
  confirmPayment: '/confirmpayment',
  recentActivity: '/recentactivity',
  pendingPayments: '/pendingpayments'
};

export const FriendUrls = {
  allFriendDashboard: '/allfrienddashboard',
  friendDashboard: '/frienddashboard',
  inviteFriends: '/invitefriends',
  allManageFriend: '/allfrienddashboard/managefriends',
  allPendingFriend: '/allfrienddashboard/pendingfriends',
  pendingFriends: 'pendingfriends',
  manageFriends: 'managefriends',
  becomeAffiliate: '/becomeaffiliate'
};

export const EarningandExpenseUrls = {
  earningExpenseDashboard: '/earningexpensedashboard',
  earningSummary: '/earningssummary',
  earningInvestorDashboard: '/maininvestor',
  expenseSummary: '/expensessummary',
  myProductRevenue: '/myproductrevenue',
  directCustomerCommission: '/directcustomercommission',
  directCommission: '/earningsdirectcommission',
  binaryCommission: '/binarycommission',
  matchingBonus: '/matchingbonus',
  fastStartBonus: '/faststartbonus',
  matchingbonuses: 'earningsmatchingbonuses',
  Paycirclecalculations: '/earningsmatchingbonuses/Paycirclecalculations',
  Paycirclecalculationsdetails: '/earningsmatchingbonuses/Paycirclecalculationsdetails/:payCircleId',
  transactions: '/earningsmatchingbonuses/transactions',
  commissions: 'earningscommissions',
  binaryPoint: '/earningscommissions/binarypoint',
  binaryPayout: '/earningscommissions/binarypayout',
  binaryPayoutPaycircleNumber: '/earningscommissions/binarypayoutoverview/:paycircleNumber',
  binaryPayoutrelatedPaycircle: '/earningscommissions/binarypayoutrelatedpaycircle/:relatedPaycircle',
  binaryCommission: '/earningscommissions/binarycommission',
  binaryCommissionpaycircle: '/earningscommissions/binarycommission/paycircle',
  binaryCommissiontransaction: '/earningscommissions/binarycommission/transactions',
  binaryCommissiontransactionid: '/earningscommissions/binarycommission/transactions/:transactionId',
  BinaryCommissionpayCircleDetail: '/earningscommissions/BinaryCommissionpaycircledetail/:payCircleId',
  BinaryCommissionpayoutNumberDetail: '/earningscommissions/BinaryCommissionpayoutnumberdetail/:payoutNumberId',
  suspended: '/earningscommissions/suspended',
  suspendedBinaryPoint: '/earningscommissions/suspended/binarypoint',
  suspendedBinaryCommission: '/earningscommissions/suspended/binarycommission',
  transfer: '/earningscommissions/transfer',
  payoutStatus: '/payoutstatus',
  payoutDashboard: '/payoutdashboard1',
  performanceFee: '/performancefee',
  referralBonus: '/referralbonus',
  notFound: '*'
};

export const PaymentUrls = {
  paymentOption: '/paymentoption',
  managePaymentOptions: '/managepaymentoptions',
  currentCreditCard: '/managepaymentoptions/currentcraditcard',
  currentCryptoProvider: '/managepaymentoptions/currentcryptoprovider',
  currentBankAccount: '/managepaymentoptions/currentbankaccount',
  newCreditCard: '/managepaymentoptions/newcraditcard',
  addCreditCard: '/managepaymentoptions/addcraditcard',
  newCryptoProvider: '/managepaymentoptions/newcryptoprovider',
  newBankAccount: '/managepaymentoptions/newbankaccount',
  editCreditCard: '/managepaymentoptions/editcreditcard',
  payouts: '/payouts',
  payoutDashboard: '/payoutdashboard',
  allPayout: 'allpayout',
  incomingsPayout: '/allpayout/incomingspayout',
  outgoingsPayout: '/allpayout/outgoingsPayout',
  addBankAccount: '/addbankaccount',
  WalletCardManagment: '/walletcardmanagment',
  allLogHistory: 'allloghistory',
  bankLogHistory: '/allloghistory/bankloghistory',
  cardLogHistory: '/allloghistory/cardloghistory',
  transactionHistoryDashboard: 'transactionhistorydashboard',
  allTransactionhistory: '/transactionhistorydashboard/alltransactionhistory',
  acceptedTransaction: '/transactionhistorydashboard/acceptedtransaction',
  rejectedTransaction: '/transactionhistorydashboard/rejectedtransaction'
};

export const SettingUrls = {
  settings: '/settings',
  myInformation: '/settings/myinformation',
  notifications: '/settings/notifications',
  kycRegistration: '/settings/kycregistration',
  onBoardMainScreen: '/onboardmainscreen',
  individualOnBoard: '/settings/individualonboard',
  businessOnBoard: '/settings/companyonboard',
  updateAddress: '/updateaddress',
  updatePassword: '/updatepassword',
  prefferedSetting: '/prefferedsetting',
  updateProfile: '/updateprofile',
  help: '/help',
  allsettings: 'allsettings',
  information: '/allsettings/information',
  companyInformation: '/allsettings/companyinformation',
  address: '/allsettings/address',
  password: '/allsettings/password',
  document: '/allsettings/document',
  document2: '/allsettings/document2',
  notification: '/allsettings/notification',
  borkers: '/allsettings/brokers',
  // releaseNotes: '/releasenotes',
  updateInformation: '/updateinformation'
};

export const BrokerUrls = {
  listing: '/brokersListing',
  accountDetail: '/brokeraccountDetail',
  selectBroker: '/selectBrokerNew',
  brokerSignup: '/brokerSignup',
  myBroker: '/myBroker',
  brokerMain: '/brokeraccounts'
};

export const ProductSelectionUrls = {
  productSelection: '/productselection',
  selectionWizard: '/selectionwizard',
  productDashboard: '/productdashboard',
  productSettings: '/productsettings',
  selectBrokerAccounts: '/selectbrokeraccounts',
  summary: '/summary',
  productOverview: '/productoverview',
  simpleSelection: '/simpleselection',
  updateProduct: '/updateproduct',
  updateSimpleSelection: '/updatesimpleselection',
  updateProductSummary: '/updateproductsummary',
  viewAllProducts: '/viewallproducts'
};

export const VoucherUrls = {
  voucherDashboard: '/voucherdashboard',
  planVoucher: '/planvoucher',
  upgradeProfile: '/upgradeprofile',
  allVoucher: 'allvoucher',
  myVoucher: '/allvoucher/myvoucher',
  myUsed: '/allvoucher/myvoucher/myused',
  myActive: '/allvoucher/myvoucher/myactive',
  myExpired: '/allvoucher/myvoucher/myexpired',
  sharedVoucher: '/allvoucher/sharedvoucher',
  sharedUsed: '/allvoucher/sharedvoucher/sharedused',
  sharedActive: '/allvoucher/sharedvoucher/sharedactive',
  sharedExpired: '/allvoucher/sharedvoucher/sharedexpired'
};

export const MarketingUrls = {
  marketingDashboard: '/marketingdashboard'
};

export const PepperStone = 'https://pepperstone.com/';

export const LogInAsClientUrls = {
  loginAsClinet: '/loginasclient',
  giveAccessToSupport: '/giveaccesstosupport'
};
