import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  products: [],
  userProducts: [],
  userProduct: {},
  userBalance: '',
  userTradingBalance: '',
  tradeStatus: null,
  myPlanResponse: {},
  markets: [],
  selectedOptionForTableColumn: [],
  productOverviewMarkets: [],
  productOverview: {
    id: '',
    balance: '',
    riskValue: '',
    riskMin: '',
    riskMax: '',
    profileMin: '',
    profileMax: '',
    profileValue: '',
    styleMin: '',
    styleMax: '',
    styleValue: '',
    roiMin: '',
    roiMax: '',
    roiValue: '',
    userProductId: '',
    accountBalance: '',
    productName: '',
    status: '',
    accountStatusId: '',
    tblProductId: '',
    productNickName: '',
    returnOnInvestmentPercentage: '',
    ctraderInfoId: 0,
    ctid: ''
  },
  productSettings: {
    isActionUpdate: false
  },
  productOverviewGraph: [],
  ctraderInfoId: [],
  activeProduct: {
    ctid: 0,
    ctraderInfoId: 0,
    productName: '',
    productNickName: '',
    isLive: null
  },
  investmentDashboard: {
    currentDate: moment(),
    month: '',
    year: moment().year()
  },
  deleteProduct: false,
};

export const investmentProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setUserProducts: (state, action) => {
      state.userProducts = action.payload;
    },
    setUserProduct: (state, action) => {
      state.userProduct = action.payload;
    },
    setUserBalance: (state, action) => {
      state.userBalance = action.payload;
    },
    setUserTradingBalance: (state, action) => {
      state.userTradingBalance = action.payload;
    },
    setUserTradeStatus: (state, action) => {
      state.tradeStatus = action.payload;
    },
    setUserMyPlanResponse: (state, action) => {
      state.myPlanResponse = action.payload;
    },
    setUserMarkets: (state, action) => {
      state.markets = action.payload;
    },
    updateMyPlan: (state, action) => {
      const payload = action.payload;
      return {
        ...state,
        products: payload?.products,
        userProduct: payload?.userProduct,
        userBalance: payload?.balance,
        tradeStatus: payload?.userStatus,
        markets: payload?.markets
      };
    },
    updateSelectedOptionForTableColumn: (state, action) => {
      state.selectedOptionForTableColumn = action.payload;
    },
    setProductOverviewMarkets: (state, action) => {
      state.productOverviewMarkets = action.payload;
    },
    setctraderInfoId: (state, action) => {
      state.ctraderInfoId = action.payload;
    },
    setAccountStatusId: (state, action) => {
      state.productOverview.accountStatusId = action.payload;
    },
    setIsActionUpdate: (state, action) => {
      state.productSettings.isActionUpdate = action.payload;
    },
    setProductOverview: (state, action) => {
      const payload = action.payload;
      state.productOverview = {
        ...payload,
        id: payload?.id ?? state.productOverview.id,
        balance: payload?.balance ?? state.productOverview.balance,
        riskValue: payload?.riskValue ?? state.productOverview.riskValue,
        riskMin: payload?.riskMin ?? state.productOverview.riskMin,
        riskMax: payload?.riskMax ?? state.productOverview.riskMax,
        profileMin: payload?.profileMin ?? state.productOverview.profileMin,
        profileMax: payload?.profileMax ?? state.productOverview.profileMax,
        profileValue: payload?.profileValue ?? state.productOverview.profileValue,
        styleMin: payload?.styleMin ?? state.productOverview.styleMin,
        styleMax: payload?.styleMax ?? state.productOverview.styleMax,
        styleValue: payload?.styleValue ?? state.productOverview.styleValue,
        roiMin: payload?.roiMin ?? state.productOverview.roiMin,
        roiMax: payload?.roiMax ?? state.productOverview.roiMax,
        roiValue: payload?.roiValue ?? state.productOverview.roiValue,
        userProductId: payload?.userProductId ?? state.productOverview.userProductId,
        productNumber: payload?.productNumber ?? state.productOverview.productNumber,
        accountBalance: payload?.accountBalance ?? state.productOverview.accountBalance,
        productName: payload?.productName ?? state.productOverview.productName,
        status: payload?.status ?? state.productOverview.status,
        accountStatusId: payload?.accountStatusId ?? state.productOverview.accountStatusId,
        tblProductId: payload?.tblProductId ?? state.productOverview.tblProductId,
        productNickName: payload?.productNickName ?? state.productOverview.productNickName,
        ctraderInfoId: payload?.ctraderInfoId ?? state.productOverview.ctraderInfoId,
        roiAmount: payload?.roiAmount ?? state.productOverview.roiAmount,
        ctid: payload?.ctid ?? state?.productOverview?.ctid,
        returnOnInvestmentPercentage: payload?.graph?.table[0].returnOnInvestmentPercentage ?? state.productOverview.returnOnInvestmentPercentage
      };
    },
    setProductOverviewGraph: (state, action) => {
      if (Array.isArray(action)) {
        state.productOverviewGraph = action;
      } else {
        state.productOverviewGraph = action.payload;
      }
    },
    setInvestmentDuration: (state, action) => {
      const payload = action.payload;
      state.investmentDashboard = {
        ...payload,
        month: payload.month,
        year: payload.year
      };
    },
    setNavigateProduct: (state, action) => {
      const payload = action.payload;
      // investmentProductsSlice.caseReducers.setProductOverview(state, action);
      investmentProductsSlice.caseReducers.setProductOverview(state, action);
      investmentProductsSlice.caseReducers.setProductOverviewGraph(state, payload?.graph?.table1 ? payload?.graph?.table1 : state.productOverviewGraph);
    },
    setActiveProduct: (state, action) => {
      const payload = action.payload;
      state.activeProduct = action.payload;
    },
    setDeleteProduct: (state, action) => {
      state.deleteProduct = action.payload
    }
  }
});

export const {
  setProducts,
  setUserProducts,
  setUserProduct,
  setUserBalance,
  setUserTradingBalance,
  setUserTradeStatus,
  setUserMyPlanResponse,
  setUserMarkets,
  updateMyPlan,
  updateSelectedOptionForTableColumn,
  setProductOverviewMarkets,
  setProductOverview,
  setProductOverviewGraph,
  setNavigateProduct,
  setAccountStatusId,
  setctraderInfoId,
  setInvestmentDuration,
  setActiveProduct,
  setIsActionUpdate,
  setDeleteProduct
} = investmentProductsSlice.actions;

export default investmentProductsSlice.reducer;
