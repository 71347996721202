export const freedomConstants = {
  APP_ID: 2
};

export const freedomUserType = {
  Customer: 1,
  Distributor: 2,
  Friend: 3,
  Employee: 4,
  Trader: 5
};

export const freedomUserSubType = {
  NoTrader: null,
  Trader: 1,
  LeadTrader: 2,
  ManagerTrader: 3,
}

export const freedomLanguage = {
  english: 1
};

export const platformTypeId = {
  Email: 1,
  WhatsApp: 2,
  SMS: 3,
  AppNotification: 4
};

export const inviteStatus = {
  Pending: 1,
  Onboarding: 2,
  Completed: 3,
  ResendMessage: 4,
  Expire: 5,
  PendingInvestorToAffiliate: 6,
  LegNotSet: 7,
  CompanyApprovalPendingFromComplaince: 8,
  traderToAffiliate: 10,
  traderToInvestor: 11
};

/**
 * template status starts
 */
export const invitationEmailTemplates = {
  investorTemplateStart: 1,
  investorTemplateEnd: 5,
  affiliateTemplateStart: 6,
  affiliateTemplateEnd: 10,
  investorToTraderStart: 21,
  investorToTraderEnd: 25,
  affiliateToTraderStart: 16,
  affiliateToTraderEnd: 20
}

export const InvitedByPlatformType = {
  FreedomWebPortal: 1,
  LandingPages: 2
};

export const parentUserLeg = {
  Auto: 0,
  Left: 1,
  Right: 2
};

export const freedomTheme = {
  Light: 'L',
  Dark: 'D'
};

export const mobileLength = {
  Min: 6,
  Max: 15
};

// Payment OTP Type for CreditCard and BankInfo
export const paymentOTPType = {
  CreditCard: 1,
  BankInfo: 2,
  withdrawAmount: 4,
  account: 5,
  passwordChange: 6,
  pendingPayments: 7
};

/// Payment Satus for OTP Genrate, Validate and Resend
export const paymentOTPStatus = {
  Genrate: 1,
  Validate: 2,
  Resend: 3
};

export const InvesterType = {
  invester: 1,
  pendingInvester: 2
};

export const AffiliateType = {
  Firstline: 1,
  ManageAffilates: 2,
  Pending: 3,
  Downline: 4
};
// export const InvestmentPlanType = {
//   Basic: 1,
//   Advance: 2,
//   Pro: 3,
//   Supreme: 4,
//   Elite: 5
// };

export const GraphPeriod = {
  Week: 1,
  Month: 4,
  Year: 2
};

export const GraphType = {
  affiliate: 'affiliate',
  customer: 'customer'
};

/**
 * transaction fee for adding and removing amount to the system
 */
export const transactionFee = {
  creditCardDeposit: 2,
  cryptoCurrencyDeposit: 2,
  bankAccountDeposit: 2,
  FreedomDebitCardDeposit: 2,
  /**withdraw */
  creditCardWithdraw: 2,
  cryptoCurrencyWithdraw: 2,
  bankAccountWithdraw: 2,
  FreedomDebitWithdraw: 2,
  FreedomInternalAccount: 2
};

/**
 * Earning and Expense Module Status
 */

export const earningModuleStatus = {
  Inactive: 0,
  Active: 1,
  Settled: 2,
  Pending: 3,
  Postponed: 4,
  Cancelled: 5,
  Expired: 6,
  Historic: 7,
  Flushed: 8,
  All: 99
};
export const suspendedBinaryPoint = {
  All: 99,
  Leftlegside: 1,
  Rightlegside: 2,
  Investorpoints: 3,
  Affiliatespoints: 4
};

export const earningSummaryStatusType = {
  All: 99,
  Type: 'Type',
  Paycircle: 'Paycircle',
  PayoutDate: 'PayoutDate'
};

//pagination skip count
export const countSkip = 250;

////////////////////////////////////
export const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

export const NAME_REGEX = /^[a-zA-Z\u4E00-\u9FFFæøåÆØÅ\s]*$/;
// export const NAME_REGEX = /^[ a-zA-Z\-’]+$/;
// export const YEAR_REGEX = /^([1][9]\d\d|2[0-9][0-0][0-3])$/;
export const NUMBER_REGEX = /^\d*\.?\d*$/;
export const AlphaNumeric_REGEX = /^[A-Za-z0-9]+$/;
export const SpecialAlphaNumeric_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/;

///TimeOutMinute//////
export const timeOutMinute = 30;

/**
 * Credit card constant section
 */
export const paymentMethodconstant = {
  card: 'Card',
  bank: 'Bank',
  addBank: 'Add Bank',
  addCard: 'Add Card',
  primaryMethod: 'Primary Method',
  internalAccount: 'Internal Account'
};

/**
 * Account Type
 */

export const accountType = {
  standard: 1,
  silver: 2,
  gold: 3,
  platinum: 4,
  exclusive: 9,
  vip: 10,
  unknown: 1000
};

/**
 * Payment Account Type
 */
export const paymentAccountType = {
  internalAccount: 1,
  creditCardAccount: 2,
  bankAccount: 4
};

/**
 * Order Type
 */

export const orderTypes = {
  deposit: 1,
  withdraw: 2,
  account: 3,
  product: 4,
  voucher: 5,
  annualSubscriptionFee: 8,
  pendingInvoice: 9,
  companyDocumentVerivicationFee: 11,
  unknown: 1000
};

export const usersProfieType = {
  Individual: 1,
  Company: 2
};

export const documentTypes = {
  Selfie: 1,
  CNIC: 2,
  residentCard: 3,
  utilityBill: 4
};

export const withDrawOption = {
  bank: 'Bank Account',
  card: 'Credit Card',
  crypto: 'Crypto'
};
export const tradingStatus = {
  Idle: 0,
  Active: 1,
  Stopped: 2,
  Paused: 3,
  PaymentBlocked: 4,
  TradingBlocked: 5,
  IdleToActive: 6,
  Delink: 7,
  Authorization_in_progress: 8,
  emergencyStop: 9,
  NOChange: 10,
  productTrialExpired: 16,
  demoTrialExpired: 17,
};
export const graphGrowthAndAccumulateDropDown = [
  {
    ID: 0,
    Name: 'Growth',
    Value: 0
  },
  {
    ID: 1,
    Name: 'Accumulate',
    Value: 1
  }
];

export const typesOfCreditCard = {
  visa: 'visa',
  master: 'mastercard'
};

export const featureTypes = {
  pinModuleToBottomBar: 1,
  pinLiveTradeHeaders: 2,
  pinTradeHistoryHeaders: 3
};

export const FreedomTradingStatus = {
  Active: 1,
  Delinked: 2,
  TradingUnauthorized: 3,
  TokenExpired: 4
};

export const clientLoginAccessLevel = {
  readOnly: 1,
  writeOnly: 2
};

export const reInvestStatus = {
  Manual: 1,
  Automatic: 2
};


// Product overview and product selection screen

export const productScreenId = {
  productOverview: 1,
  productSelection: 2,
  productSettingsDashboard: 3
}

/**
 * product type id
 */

export const productTypeId = {
  All: 0,
  universal: 1,
  custom: 2
}