import { Box, Grid, useTheme } from '@mui/material'
import React from 'react'
import FreedomTypographyText from '../SharedComponent/FreedomTypographyText/FreedomTypographyText'
import { images } from '../../url/SvgLinks';

const youtubeIcon = images.youtubeIcon;

const YoutubeVideo = ({text="Watch Intro", onClick, height}) => {
    const theme = useTheme()
  return (
    <Box 
        sx={{
            cursor: 'pointer', 
            display: 'flex', 
            alignItems: 'center', 
            borderRadius: '8px',
            justifyContent:'center', 
            border: `2px solid ${theme.palette.border.containerborder}`, 
            padding:"10px",
            height: height || '100%'
        }}
            onClick={() => {if(onClick) onClick()}}
            >
                <img
                  src={youtubeIcon}
                  style={{
                    width: '32px',
                    height: '24px',
                    marginRight: '8px'
                  }}
                  alt="img"
                />
              <Grid item>
                <FreedomTypographyText style={{userSelect: 'none'}}>{text}</FreedomTypographyText>
              </Grid>
            </Box>
  )
}

export default YoutubeVideo